html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: "Raleway", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
input {
  box-shadow: none;
  -webkit-box-shadow: none;
}

body a {
  text-decoration: none;
  color: #fff;
}

.mainLink a {
  text-decoration: none;
  color: #fff;
}

.ft-container {
  min-height: 100vh;
}

.slick-slide div:hover {
  transition: transform .5s; 
  transform: scale(1.02);  
}

.slick-prev:before {
  color: #38394d !important;
  background-color: #fff;
}
.slick-next:before {
  color: #38394d !important;
  background-color: #fff;
}

@media screen and (min-width: 1050px) {
  .ft-container {
    margin-top: 4%;
    padding-top: 4%;
  }
}

@media screen and (max-width: 950px) {
  .ft-container {
    margin-top: 15%;
    padding-top: 5%;
  }
}

.ft-verify {
  margin-top: 6%;
  padding-top: 5%;
}

/* .MuiOutlinedInput-root{
  background: #ffff
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.fw-divider {
  padding-top: 15px;
  /* padding-bottom:75px; */
}

.trackTitle {
  padding-right: 15px;
}

.user-title {
  font-style: italic;
  padding-right: 15px;
  color: rgb(133, 133, 133);
}

/* .fa-play-circle{
  color:rgb(255, 255, 255, .4);
} */

/* .Mui-focused {
  border-bottom: 1px solid rgb(131, 130, 130);
  border-top: none;
} */

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.MuiBadge {
  color: #fff !important;
}

.MuiBadge-colorSuccess {
  background: #ff3705 !important;
  color: #fff !important;
}

.MuiBadge-colorInfo {
  background: #1c749c !important;
  color: #fff !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px grey;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #153763;
  border-radius: 5px;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collections-container {
  padding-left: 35px !important;
  padding-right: 25px;
}

@media screen and (max-width: 8000px) {
  .collections-container {
    padding-left: 35x;
    margin-right: 150px;
  }
}

.track-actions {
  margin-right: 75px;
}

/* .SnackbarItem-variantSuccess{
  margin-top:50px;
} */

/* Audio Player */

@-webkit-keyframes blink { 
  50% { border-color: #636363; } 
}
@keyframes blink { 
  50% { border-color: #636363; } 
}
span#pauseBorder {
   padding: 1px;
   border: 1px solid transparent;
   border-radius: 50%;
   color: #41eefe;
}

#pauseBorder{
   animation: blink .7s step-end infinite alternate;
   -webkit-animation: blink .7s step-end infinite alternate;
}



.audiowrapper {
  display: flex;
  margin: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height:90px;
  padding-bottom: none !important;
  /* padding-bottom: 15px; */
  background-color: #38394d;
  border-top: 1px solid #7e7e7e;
  z-index: 1500;
}

/* .audiowrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  color: whitesmoke;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #00031e;
  border-top: 1px solid #c1c1c1;
  z-index: 50000;
} */

.audiowrapper a {
  color: whitesmoke;
  text-decoration: none;
  height:55px;
}

/* .aprow {
  height: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
} */

/* .apcolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex: 1;
} */

.zoom-column {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.audio-controls-column {
  display: flex;
  color:whitesmoke;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 100px;
}

.player-column {
  height: 95px;
}

.trackactionblock {
  white-space: nowrap;
}

.trackActions {
  font-size: 0.75em;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
}

.react-waves {
  /* display: inline-block;
  align-items: center;
  justify-content: center; */
  width: 100% !important;
  margin: 0 !important;
  padding: none !important;
}

.audiowrapper a:hover {
  color: #ec407a;
}

.waveform {
  background-color: rgb(0, 3, 30, 0.1);
  position: relative;
  padding: none !important;
  margin: none !important;
  border-radius: 4px;
  border: solid 1px rgb(67, 69, 85);
  height: 50px;
  width: 100%;
}

.wave {
  /* margin-top:2vh; */
  vertical-align: middle;
  width: 100%;
}

.ftwaveform {
  margin: 0 !important;
  padding: 0 !important;
}

.ft-audio-wrapper {
  position: relative;
  border: 1px solid rgb(78, 78, 78);
}

.fttimeline {
  margin: 0 !important;
  padding: 0 !important;
}

.audiobtn-wrapper {
  margin-top: 25px;
  align-self: flex-start;
}

.audio-zoom {
  align-self: flex-end;
  align-items: center;
  justify-content: center;
}

.audiobtn {
  color: white;
  width: 50px;
  /* margin-left:15px; */
  font-size: 1em;
}

.react-waves {
  width: 100%;
}

.audio-info {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95em;
  color: whitesmoke;
  max-height: 20px;
  margin-bottom: 3px;
}

.audio-info .startcounter {
  text-align: left;
  color:white;
}

.audio-info .endcounter {
  float: right;
  margin-right: 15px;
  color:white;
}

.audio-info .trackinfo {
  text-transform: uppercase;
}

.player-disabled {
  display: none;
}

.player-enabled {
  display: inline;
}

.sptitle {
  font-family: "Fira Sans", sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  padding: 10px;
  color: rgb(0, 0, 0);
}

.spparagraph {
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  padding: 10px;
  color: rgb(0, 0, 0, 0.85);
}
